import React from "react";
import axios from "axios";
import {
  Container,
  Button,
  Row,
  Col,
  Table,
  Card,
  Form,
} from "react-bootstrap";
import DataProv from "./DataProv";
const proxyUrl = "https://cors-anywhere.herokuapp.com/";
const APIDunia = "https://api.kawalcorona.com";
const APINegara = "https://api.kawalcorona.com/indonesia/";
const APIProvinsi = "https://api.kawalcorona.com/indonesia/provinsi/";

class Corona extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataProvinsi: null,
      dataNegara: null,
      isLoading: false,
      dataProvinsiSearch: null,
    };
    this.data = this.data.bind(this);
  }

  async data() {
    await fetch(proxyUrl + APINegara)
      .then((response) => response.json())
      .then((response) => this.setState({ dataNegara: response }));
    await fetch(proxyUrl + APIProvinsi)
      .then((response) => response.json())
      .then((response) =>
        this.setState({
          dataProvinsi: response,
          isLoading: false,
        })
      );
  }
  searchHandling = (e) => {
    const search = e.target.value;
    const { dataProvinsi } = this.state;
    const dataProvinsiSearch = dataProvinsi.filter((data) => {
      return (
        data.attributes.Provinsi.toLowerCase().search(search.toLowerCase()) !==
          -1 ||
        data.attributes.Kasus_Posi.toString().search(search) !== -1 ||
        data.attributes.Kasus_Semb.toString().search(search) !== -1 ||
        data.attributes.Kasus_Meni.toString().search(search) !== -1
      );
    });
    this.setState({ dataProvinsiSearch: dataProvinsiSearch });
  };
  componentWillMount() {
    this.setState({
      isLoading: true,
    });
    this.data();
  }

  render() {
    const { dataProvinsi, dataNegara, dataProvinsiSearch } = this.state;
    return (
      <Container className="mt-4">
        {this.state.isLoading ? (
          <div style={{ margin: "100px 0 200px 0" }}>
            Sedang Mengambil Data....
          </div>
        ) : (
          <React.Fragment>
            <h4 className="mb-4">
              Data Penyebaran Covid-19 di {dataNegara[0].name}
            </h4>
            <Row className="justify-content-center">
              <Col lg={4} className="mt-4">
                <Row>
                  <Col md={4} lg={12}>
                    <Card bg={"warning"} border={"warning"} text={"white"}>
                      <Card.Body>
                        <Row>
                          <Col xs={5} className="justify-content-end">
                            <img
                              src={process.env.PUBLIC_URL + "/img/sick.svg"}
                              width={80}
                            />
                          </Col>
                          <Col xs={7}>
                            <h4>Positif</h4>

                            <div style={{ fontSize: 1.2 + "rem" }}>
                              {dataNegara[0].positif}
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4} lg={12}>
                    <Card bg={"primary"} border={"primary"} text={"white"}>
                      <Card.Body>
                        <Row>
                          <Col xs={5}>
                            <img
                              src={process.env.PUBLIC_URL + "/img/sembuh.svg"}
                              width={80}
                            />
                          </Col>
                          <Col xs={7}>
                            <h4>Sembuh</h4>
                            <div style={{ fontSize: 1.2 + "rem" }}>
                              {dataNegara[0].sembuh}
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4} lg={12}>
                    <Card bg={"danger"} border={"danger"} text={"white"}>
                      <Card.Body>
                        <Row>
                          <Col xs={5}>
                            <img
                              src={process.env.PUBLIC_URL + "/img/death.png"}
                              width={80}
                            />
                          </Col>
                          <Col xs={7}>
                            <h4>Meninggal</h4>
                            <div style={{ fontSize: 1.2 + "rem" }}>
                              {dataNegara[0].meninggal}
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col md={12} lg={8} className="order-lg-first">
                <Card className="mt-4">
                  <Card.Body>
                    <Row>
                      <Col sm={6}>
                        <h4>Provinsi</h4>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Control
                            type="Text"
                            className="cari"
                            onChange={(e) => this.searchHandling(e)}
                            placeholder="Cari"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="table-provinsi">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Provinsi</th>
                            <th>Positif</th>
                            <th>Sembuh</th>
                            <th>Meninggal</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!dataProvinsiSearch
                            ? dataProvinsi.map((data, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{data.attributes.Provinsi}</td>
                                  <td>{data.attributes.Kasus_Posi}</td>
                                  <td>{data.attributes.Kasus_Semb}</td>
                                  <td>{data.attributes.Kasus_Meni}</td>
                                </tr>
                              ))
                            : dataProvinsiSearch.map((data, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{data.attributes.Provinsi}</td>
                                  <td>{data.attributes.Kasus_Posi}</td>
                                  <td>{data.attributes.Kasus_Semb}</td>
                                  <td>{data.attributes.Kasus_Meni}</td>
                                </tr>
                              ))}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export default Corona;

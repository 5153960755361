import React from "react";
import { BrowserRouter, Route, Link, Switch } from "react-router-dom";
import Corona from "./Corona";
import Home from "./Home";
import Header from "./Header";
import Footer from "./Footer";
function App() {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/Corona">
          <Corona />
        </Route>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

import React from "react";

function CuciTangan() {
  return (
    <React.Fragment>
      <h4 className="mt-1">Cuci Tangan </h4>
      <div>
        Mencuci tangan dengan benar adalah cara paling sederhana namun efektif
        untuk mencegah penyebaran virus Covid-19. Cucilah tangan dengan air
        mengalir dan sabun, setidaknya selama 20 detik. Pastikan seluruh bagian
        tangan tercuci hingga bersih, termasuk punggung tangan, pergelangan
        tangan, sela-sela jari, dan kuku. Setelah itu, keringkan tangan
        menggunakan tisu, handuk bersih, atau mesin pengering tangan.
      </div>
    </React.Fragment>
  );
}

function MenggunakanMasker() {
  return (
    <React.Fragment>
      <h4 className="mt-1">Menggunakan Masker </h4>
      <div>
        Banyak yang menggunakan masker kain untuk mencegah infeksi virus Corona,
        padahal masker tersebut belum tentu efektif. Secara umum, ada dua tipe
        masker yang bisa Anda digunakan untuk mencegah penularan virus Corona,
        yaitu masker bedah dan masker N95.
      </div>
    </React.Fragment>
  );
}

function MenjagaDayaTahanTubuh() {
  return (
    <React.Fragment>
      <h4 className="mt-1">Menjaga Daya Tahan Tubuh</h4>
      <div>
        Daya tahan tubuh yang kuat dapat mencegah munculnya berbagai macam
        penyakit. Untuk menjaga dan meningkatkan daya tahan tubuh, Anda
        disarankan untuk mengonsumsi makanan sehat, seperti sayuran dan
        buah-buahan, dan makanan berprotein, seperti telur, ikan, dan daging
        tanpa lemak. Bila perlu, Anda juga menambah konsumsi suplemen sesuai
        anjuran dokter.
      </div>
    </React.Fragment>
  );
}
function PhysicalDistancing() {
  return (
    <React.Fragment>
      <h4 className="mt-1">Physical Distancing </h4>
      <div>
        Physical distancing adalah salah satu imbauan yang sering digemakan oleh
        berbagai pihak. Sebab, dengan menjaga jarak antara satu sama lain
        dipercaya dapat mengurangi risiko penyebaran virus corona. Selalu
        perhatikan jarak fisik dengan orang lain yakni minimal 1 meter. Langkah
        ini dapat diterapkan saat berada di tempat umum ataupun saat di luar
        rumah guna menangkal virus masuk ke dalam tubuh dan menyebabkan masalah
        kesehatan yang serius.
      </div>
    </React.Fragment>
  );
}
export {
  CuciTangan,
  MenggunakanMasker,
  MenjagaDayaTahanTubuh,
  PhysicalDistancing,
};

import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {
  Container,
  Row,
  Col,
  Card,
  Jumbotron,
  Tab,
  ListGroup,
} from "react-bootstrap";
import "./home.css";
import "animate.css/animate.min.css";
import {
  CuciTangan,
  MenggunakanMasker,
  MenjagaDayaTahanTubuh,
  PhysicalDistancing,
} from "./Pencegahan.js";
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        positif: 0,
        sembuh: 0,
        meninggal: 0,
      },
    };
  }
  componentWillMount() {
    fetch(
      "https://cors-anywhere.herokuapp.com/" +
        "https://api.kawalcorona.com/indonesia/"
    )
      .then((response) => response.json())
      .then((response) => this.setState({ data: response[0] }));
  }
  render() {
    const { data } = this.state;

    return (
      <React.Fragment>
        <Jumbotron className="top align-middle">
          <div>
            <h1>Covid-19</h1>
            <div>Corona Virus Disease - 2019</div>
          </div>
        </Jumbotron>
        <Container className="mt-4">
          <ScrollAnimation animateIn="fadeIn">
            <h3 className="text-center mb-2">
              Penyebaran Covid-19 di Indonesia
            </h3>
            <Row className="justify-content-center">
              <Col sm={4} xs={10}>
                <Card
                  className="data-corona"
                  style={{ borderColor: "#EFBB50" }}
                >
                  <Card.Body className="text-center">
                    <img
                      src={process.env.PUBLIC_URL + "/img/sick.svg"}
                      width={60}
                    />
                    <h5>Positif</h5>
                    <div>{data.positif}</div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4} xs={10}>
                <Card
                  className="data-corona"
                  style={{ borderColor: `#2EA0DD` }}
                >
                  <Card.Body className="text-center">
                    <img
                      src={process.env.PUBLIC_URL + "/img/sembuh.svg"}
                      width={60}
                    />
                    <h5>Sembuh</h5>
                    <div>{data.sembuh}</div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4} xs={10}>
                <Card
                  className="data-corona"
                  style={{ borderColor: `#DF4B4B` }}
                >
                  <Card.Body className="text-center">
                    <img
                      src={process.env.PUBLIC_URL + "/img/death.png"}
                      width={60}
                    />
                    <h5>Meninggal</h5>
                    <div>{data.meninggal}</div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </ScrollAnimation>
        </Container>

        <Container fluid>
          <ScrollAnimation animateIn="fadeIn">
            <Container>
              <Card className="mt-5 pengertian" style={{ width: "80%" }}>
                <Card.Body>
                  <h4>Corona(Covid-19)</h4>
                  <div className="content">
                    Virus Corona atau severe acute respiratory syndrome
                    coronavirus 2 (SARS-CoV-2) adalah virus yang menyerang
                    sistem pernapasan. Penyakit karena infeksi virus ini disebut
                    COVID-19. Virus Corona bisa menyebabkan gangguan pada sistem
                    pernapasan, pneumonia akut, sampai kematian.
                  </div>
                </Card.Body>
              </Card>
            </Container>
          </ScrollAnimation>
          <ScrollAnimation animateIn="zoomIn" offset={400}>
            <Jumbotron className="mt-5 gejala">
              <h4 className="text-center">Gejala Gejala Covid-19</h4>
              <Container className="content pt-2 pb-4 ">
                <Row>
                  <Col md={4}>
                    <Card>
                      <Card.Body className="text-center">
                        <img
                          src={process.env.PUBLIC_URL + "/img/fever.png"}
                          height={100}
                        />
                        <h4>Demam</h4>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card>
                      <Card.Body className="text-center">
                        <img
                          src={process.env.PUBLIC_URL + "/img/cough.png"}
                          height={100}
                        />
                        <h4 className="mt-1">Batuk</h4>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card>
                      <Card.Body className="text-center">
                        <img
                          src={process.env.PUBLIC_URL + "/img/breath.png"}
                          height={100}
                        />
                        <h4>Sesak Napas</h4>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card>
                      <Card.Body className="text-center">
                        <img
                          src={process.env.PUBLIC_URL + "/img/nose.png"}
                          height={100}
                        />
                        <h4>Radang Selaput Lendir</h4>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card>
                      <Card.Body className="text-center">
                        <img
                          src={process.env.PUBLIC_URL + "/img/diarrhea.png"}
                          height={100}
                        />
                        <h4>Diare</h4>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card>
                      <Card.Body className="text-center">
                        <img
                          src={process.env.PUBLIC_URL + "/img/sore-throat.png"}
                          height={100}
                        />
                        <h4>Kondisi yang Menyakitkan</h4>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </Jumbotron>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
            <Card className="pencegahan mt-n3">
              <Card.Body>
                <h4 className="mb-3">Pencegahan Covid-19</h4>
                <Tab.Container
                  id="list-group-tabs-example"
                  defaultActiveKey="#cuci-tangan"
                >
                  <Row>
                    <Col sm={4}>
                      <ListGroup className="label-pencegahan">
                        <ListGroup.Item action href="#cuci-tangan">
                          Cuci Tangan
                        </ListGroup.Item>
                        <ListGroup.Item action href="#menggunakan-masker">
                          Menggunakan Masker
                        </ListGroup.Item>
                        <ListGroup.Item action href="#menjaga-daya-tahan-tubuh">
                          Menjaga Daya Tahan Tubuh
                        </ListGroup.Item>
                        <ListGroup.Item action href="#physical-distancing">
                          Physical Distancing
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col sm={8}>
                      <Tab.Content className="ket-pencegahan">
                        <Tab.Pane eventKey="#cuci-tangan">
                          <Card>
                            <Card.Body>
                              <CuciTangan />
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#menggunakan-masker">
                          <Card>
                            <Card.Body>
                              <MenggunakanMasker />
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#menjaga-daya-tahan-tubuh">
                          <Card>
                            <Card.Body>
                              <MenjagaDayaTahanTubuh />
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#physical-distancing">
                          <Card>
                            <Card.Body>
                              <PhysicalDistancing />
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Card.Body>
            </Card>
          </ScrollAnimation>
        </Container>
      </React.Fragment>
    );
  }
}

export default Home;

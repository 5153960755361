import React from "react";
import { Container, Row, Col, Jumbotron } from "react-bootstrap";
export default function Footer() {
  return (
    <React.Fragment>
      <Jumbotron className="p-md-5 footer">
        <Row>
          <Col xs={6} className="pl-5">
            <div>#dirumahaja</div>
            <div>#stayathome</div>
          </Col>
          <Col xs={6} className="pr-5">
            <div className="text-right">Copyright © Damai</div>
          </Col>
        </Row>
      </Jumbotron>
    </React.Fragment>
  );
}

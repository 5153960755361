import React from "react";
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./home.css";
const Header = () => {
  return (
    <Navbar bg="light" sticky="top" expand="lg">
      <Container>
        <Link to="/" className="navbar-brand">
          Melawan Corona
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto mr-4">
            <Link to="/" className="nav-link">
              Home
            </Link>
            <Link to="/corona" className="nav-link">
              Data Penyebaran
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
